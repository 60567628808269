// extracted by mini-css-extract-plugin
export var smallPhone = "320px";
export var largePhone = "480px";
export var tabletPortrait = "768px";
export var tabletLandscape = "1024px";
export var smallDesktop = "1100px";
export var mediumDesktop = "1300px";
export var largeDesktop = "1400px";
export var xlDesktop = "1600px";
export var xxlDesktop = "1900px";
export var xxxlDesktop = "2150px";
export var BtnPlay = "bf_dc";
export var icon = "bf_n";
export var circle = "bf_dd";
export var triangle = "bf_df";
export var text = "bf_bZ";
export var aniSpin = "bf_l";