import React, { useState } from 'react'
import { motion } from 'framer-motion'
import loadable from '@loadable/component'
import PropTypes from 'prop-types'

import { Img } from '@/components/Img'
import { BtnPlay } from '@/components/BtnPlay'

import * as styles from './VideoPlayer.styles.scss'

const LoadableVideoEmbed = loadable(() => import('@/components/VideoEmbed'), {
  resolveComponent: (components) => components.VideoEmbed,
})

const VideoPlayer = ({ aspectRatio, poster, src }) => {
  const spacer = aspectRatio ? `${aspectRatio * 100}%` : undefined
  const [isPlaying, setIsPlaying] = useState(false)
  const handlePlayVideo = () => setIsPlaying(true)
  const handleOnEnd = () => setIsPlaying(false)

  return (
    <div className={styles.VideoPlayer}>
      {spacer && <div style={{ paddingTop: spacer }} />}
      {!!poster && (
        <motion.button
          type="button"
          className={styles.poster}
          aria-label="Play Video"
          onClick={handlePlayVideo}
          initial={{ opacity: 0 }}
          animate={{ opacity: isPlaying ? 0 : 1 }}
          style={{ pointerEvents: isPlaying ? 'none' : 'auto' }}
        >
          {poster.image && <Img {...poster.image} objectFit="cover" isFull />}
          {/* {poster.video && <VideoBg src={poster.video} />} */}
          <div className={styles.btnPlay}>
            <BtnPlay />
          </div>
        </motion.button>
      )}
      {isPlaying && (
        <LoadableVideoEmbed
          onEnded={handleOnEnd}
          src={src}
          isPlaying={isPlaying}
          muted={false}
          hasControls={true}
        />
      )}
    </div>
  )
}

VideoPlayer.defaultProps = {
  aspectRatio: 9 / 16,
}

VideoPlayer.propTypes = {
  aspectRatio: PropTypes.number,
  poster: PropTypes.shape({
    image: PropTypes.object,
    video: PropTypes.string,
  }),
  src: PropTypes.string,
}

export { VideoPlayer }
