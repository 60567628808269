const ease = [0.165, 0.84, 0.44, 1]

const variants = {
  video: {
    hidden: {
      y: 100,
    },
    visible: {
      y: 0,
      transition: {
        duration: 1.5,
        ease: ease,
      },
    },
  },
}

export default variants
