import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { RichText } from '@/components/RichText'
import * as styles from './BodyText.styles.scss'

const BodyText = ({ children, className, type, tag, isRichText }) => {
  const Tag = tag
  return (
    <Tag className={classNames(styles[type], className)}>
      {isRichText ? <RichText>{children}</RichText> : children}
    </Tag>
  )
}

BodyText.defaultProps = {
  type: 'p1',
  tag: 'div',
  isRichText: true,
}

BodyText.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  type: PropTypes.string,
  tag: PropTypes.string,
  isRichText: PropTypes.bool,
}

export { BodyText }
