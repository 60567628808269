import React from 'react'
import { motion } from 'framer-motion'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'

import { Section } from '@/components/Section'
import { Container } from '@/components/Container'
import { VideoPlayer } from '@/components/VideoPlayer'

import variants from './Video.motion'
import * as styles from './Video.styles.scss'

const Video = ({ bgColor, video, posterImg }) => {
  const { ref, inView } = useInView()

  return (
    <Section className={styles.Video} bgColor={bgColor}>
      <Container>
        <motion.div
          ref={ref}
          initial="hidden"
          animate={inView ? 'visible' : 'hidden'}
        >
          <motion.div className={styles.video} variants={variants.video}>
            <VideoPlayer
              poster={{ image: posterImg }}
              src={video.url}
              aspectRatio={video.aspectRatio}
            />
          </motion.div>
        </motion.div>
      </Container>
    </Section>
  )
}

Video.propTypes = {
  caption: PropTypes.node,
  video: PropTypes.shape({
    url: PropTypes.string,
    aspectRatio: PropTypes.number,
    poster: PropTypes.object,
  }),
  bgColor: PropTypes.string,
}

export { Video }
